<template>
<div>
 
  <PageHeaderLayout>
       <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('newVisitis')">
        <div class="card-panel-icon-wrapper icon-people">
          <i class="iconfont el-icon-user" style="font-size:38px"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            七日内提醒人
          </div>
          <count-to :start-val="0" :end-val="user_number" :duration="2600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('messages')">
        <div class="card-panel-icon-wrapper icon-message">
          <i class="iconfont el-icon-user" style="font-size:38px"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            当天认领人数
          </div>
          <count-to :start-val="0" :end-val="book_number" :duration="3000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('purchases')">
        <div class="card-panel-icon-wrapper icon-money">
          <i class="iconfont el-icon-user" style="font-size:38px"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            当日成交人
          </div>
          <count-to :start-val="0" :end-val="sale_total" :duration="3200" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('shoppings')">
        <div class="card-panel-icon-wrapper icon-shopping">
          <i class="iconfont el-icon-money" style="font-size:38px"></i>
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            当日成交额
          </div>
          <count-to :start-val="0" :end-val="sale_numer" :duration="3600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
  </el-row>
      <div class="main-page-content">
        <el-table  :data="customerList"  :loading="loadingStaus" :pagingData="pagingData">
          <el-table-column
            label="顾客名称">
            <template slot-scope="scope">
              <span>{{scope.row.CS.customer_name}}</span>
            </template>
          </el-table-column>
         
          <el-table-column
            label="通知时间">
            <template slot-scope="scope">
              <span>{{formatDate(scope.row.notice_date)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="电话">
            <template slot-scope="scope">
              <span>{{scope.row.CS.customer_tel}}</span>
            </template>
          </el-table-column>
           <el-table-column
            label="阶段">
            <template slot-scope="scope">
              <span>{{getPhase(scope.row.CS.customer_phase)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="编辑" placement="top-start"   >
                  <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row)"></el-button>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
        </el-table>
       <pagination
        v-show="total>0"
        :total="total"
        :page.sync="current_page"
        :limit.sync="page_size"
        @pagination="initCustomerList"
      />
        <!-- <ApeTable ref="apeTable" :data="customerList" :columns="columns" :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row>
          <el-table-column
            slot="second-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
        </ApeTable> -->
      </div>
    </PageHeaderLayout>
     <ApeDrawer :drawerData="drawerData"  @drawerClose="drawerClose" @drawerConfirm="drawerConfirm">
      <template slot="ape-drawer">
        <el-form :model="formData" :rules="rules" ref="brandForm" label-position="right" label-width="96px">
          <el-row>
            <el-col :span="22">
              <el-form-item label="阶段" prop="selectedList" ref="categoryItem">
                <el-cascader
                  placeholder="选择阶段"
                  :props="cascaderProps"
                  :options="baseTreeList"
                  v-model="selectedPhaseList"
                  @change="cascaderPhaseChange"
                  show-all-levels
                  clearable
                  
                  filterable>
                </el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="通知时间" prop="selectedList" ref="categoryItem">
              <el-date-picker
              v-model="formData.notice_dates"
              type="date"
              value-format="timestamp"
              placeholder="选择日期"
              >
            </el-date-picker>
            </el-form-item>
            </el-col>
          </el-row>
        </el-form>     
      </template>
    </ApeDrawer>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import Pagination from '@/components/Pagination'
import ApeDrawer from '@/components/ApeDrawer'
export default {
  components: {
    CountTo,
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    Pagination
  },
  data() {
    return {
      total:0,
      current_page:1,
      page_size:10,
      sale_numer:0,
      book_number:0,
      sale_total:0,
      user_number:0,
      customerList:[],
      baseList:[],
      loadingStaus: true,
       pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      row:{},
      baseTreeList:[],
      selectedPhaseList:[],
      drawerData: {
        visible: false,
        delay:false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '权限绑定',
        width_height: '560px',
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
    
      formData: {
        is_show: '1',
      },
       cascaderProps:{
        label:'base',
        value:'id',
      },
       pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '7天后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24*7);
              picker.$emit('pick', date);
            }
          }, {
            text: '30天后',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', date);
            }
          }]
        },
       rules: {
        image: [
          {required: true, message: '上传图片', trigger: 'blur', validator:this.apeUploaderVerify},
        ],
      },
    }
  },
  created() {
    this.getStatistics();
  }, 
  mounted() {
    this.initCustomerList()
    this.initBaseData()
    this.initBaseTreeData()
  }, 
  methods: {
     async editButton(row) {
        this.row=row
        this.drawerData.visible = true
        this.drawerData.title = '状态变更(ID：'+row.id+')'
        this.formData = row
        this.selectedPhaseList=[]
        this.selectedPhaseList.push(row.CS.customer_phase)
        this.formData.notice_dates=this.formData.notice_date*1000
        this.drawerData.loading = false
    },
     // form数据提交，请求接口
    async formSubmit() {
      this.drawerData.loading_text = '玩命提交中……'
      this.drawerData.loading = true
      this.formData.notice_date=this.formData.notice_dates/1000
      await this.$api.saveCustomerDone(this.formData)
      this.$nextTick(() => {
        this.drawerData.visible = false
      })
      this.$nextTick(() => {
        if (id) {
          this.initCustomerList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initCustomerList()
    },
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    },
     async initBaseData() {
      // 获取新闻分类列表
      let {list} = await this.$api.getCustomerBaseList()
      this.baseList = list
    },
    formatDate(timestamp){
       if(timestamp==0){
        return ''
      }
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = date.getDate() + ' ';
      return Y+M+D;
    },
    cascaderPhaseChange(v) {
      if (v.length) {
        this.formData.CS.customer_phase = v[(v.length-1)]
        this.formData.CS.customer_phases = v
      } else {
         this.formData.CS.customer_phase = ''
         this.formData.CS.customer_phases = []
      }
    },
    getPhase(id){
      let obj = this.baseList.filter(function (obj) {
          return obj.id== id;
      });
      if(obj.length>0){
         return obj[0].base
      }else{
        return ''
      }
     
    },
    async switchPaging() {
      this.initCustomerList()
    },
    async initCustomerList() {
      this.loadingStaus=true
      let pagingInfo = {current_page:this.current_page,page_size:this.page_size}
      let {list}= await this.$api.getNotifyCustomerDoneList(pagingInfo)
      let _arr=[]
      list.map((item) => {
       _arr.push(Object.assign({},item))
      })
      this.customerList = _arr
      this.loadingStaus=false
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
    },
     //初始化
    async initBaseTreeData() {
      // 获取新闻分类列表
      let {list} = await this.$api.getCustomerBaseTreeList()
      let temp = list.filter(function(item){
        return item.base == "阶段"; 
      })
      if(temp.length>0){
        this.baseTreeList=temp[0].children
      }
    },
    // 处理抽屉确认
    async drawerConfirm() {
      // 调用组件的数据验证方法
      this.$refs['brandForm'].validate((valid) => {
        if (valid) {
          if (this.isChangeVideo) {
            this.isStartUpload = true
          } else {
            this.formSubmit()
          }
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
          this.$nextTick(() => {
            this.isStartUpload = false
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
